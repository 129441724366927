import React from "react";
import { Button, Container, Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";

const TamilAngelsHomePage = ({name}) => {

  const navigate = useNavigate();

  const handlenow = () => {
    navigate('/login')
  }
  return (
    <div>
      <div>
        <div className="radial-back-layer">
          <div className="round-layers"></div>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text">
                  <span>{`${name}  >  Tamil Angels`}</span>
                  <h1 className="banner-text-gradient">TAMIL ANGELS</h1>
                  <h6>Fostering a Global Network of Tamil Angel Investors</h6>
                  {/* <div className="banner-content-actions">
                  <button className="banner-viewbtn">Get in Touch</button>
                </div> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage angel-title">
                <h2>What is Tamil Angels?</h2>
                <p>
                  An Investment platform to make synergies easy for Tamil Nadu
                  based Startups and investing easy for Global & Local Tamil
                  Angel Investors. AngelsTN aims to onboard Angel Investors from
                  the across Tamil Nadu and the Tamil Diaspora across countries
                  and create an Inclusive Angel community.
                </p>
                <div className="left-content-actions">
                  <button className="ser-viewbtn">Register</button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/startupLogo.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* investors */}
      <Container>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="works-section mt-5">
              <h2>Process of finding investors</h2>
              <p className="works-sections">
                The entrepreneurs securing angel investments need a viable
                business idea and several other critical elements that can
                attract interest of angel investors. The basics an investor will
                look for
              </p>
            </div>
            <div className="track"></div>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="layer-bg-smcrd ml-3">
              <div className="works-mesh-layer"></div>
              <div className="angel-details">
                <div className="sm-crd-process">
                  <div className="angel-process-steps">
                    <div className="process-div-angel">
                      <div className="angel-rec-img">
                        <img
                          src={"/images/Rec-1.png"}
                          alt="registration"
                          className="process-img"
                        />
                      </div>
                      <div className="process-text">
                        <h3>
                          01 <span>Scalable:</span>
                        </h3>
                        <p>Is the business idea scalable?</p>
                      </div>
                    </div>
                  </div>
                  <div className="angel-process-steps">
                    <div className="process-div-angel">
                      <div className="angel-rec-img">
                        <img
                          src={"/images/Rec-2.png"}
                          alt="signup"
                          className="process-img"
                        />
                      </div>
                      <div className="process-text">
                        <h3>
                          02 <span>Passionate:</span>
                        </h3>
                        <p>Are the founders and their teams passionate?</p>
                      </div>
                    </div>
                  </div>
                  <div className="angel-process-steps angel-opacity">
                    <div className="process-div-angel">
                      <div className="angel-rec-img">
                        <img
                          src={"/images/Rec-3.png"}
                          alt="fill-application"
                          className="process-img"
                        />
                      </div>
                      <div className="process-text">
                        <h3>
                          03 <span>Unique:</span>
                        </h3>
                        <p> Is the model unique?</p>
                      </div>
                    </div>
                  </div>
                  <div className="angel-process-steps angel-opacity">
                    <div className="process-div-angel">
                      <div className="angel-rec-img">
                        <img
                          src={"/images/Rec-4.png"}
                          alt="submit-application"
                          className="process-img"
                        />
                      </div>
                      <div className="process-text">
                        <div>
                          <h3>
                            04 <span>Market size:</span>
                          </h3>
                        </div>
                        <div>
                          <p>What is addressable market size & competition?</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="angel-process-steps angel-opacity">
                    <div className="process-div-angel">
                      <div className="angel-rec-img">
                        <img
                          src={"/images/Rec-5.png"}
                          alt="track-application"
                          className="process-img"
                        />
                      </div>
                      <div className="process-text">
                        <h3>
                          05 <span>Strategy:</span>
                        </h3>
                        {/* <p>
                              Keep track of your application status right there
                              on the website.
                            </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* register */}
      <div className="container-fluid angle-width">
        <div className="angel-register-bg">
          <div className="angel-details-box">
            <ul>
              <li>
                The entrepreneurs can choose the apt syndicate with the lead or
                individual investors for investments. Similarly, the investors
                can select the syndicate or specific startups they want to
                invest in. An expert network of partners for mentoring,
                statutory services and due-diligence acts as part of the system.{" "}
              </li>
              <li>
                The model does not commit the Government or its agencies for
                co-investment or any other legally binding relationships.
              </li>
              <li>
                By augmenting the existing domestic angel capital with global
                angel fund from high net-worth Tamil NRIs we can set our startup
                ecosystem for more successes in the future.
              </li>
            </ul>
          </div>
          <div className="angle-page-register text-center mt-5">
            <h3>For registration, Please click below</h3>
            <div className="left-content-actions">
                  <button className="angel-reg">Register</button>
                </div>
          </div>
        </div>
      </div>
      <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Let’s Get Started</h4>
                    <p>TAMIL ANGELS -Fostering a Global Network of 
                    Tamil Angel Investors</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
    </div>
  );
};

export default TamilAngelsHomePage;
