import { Grid, Container, Tabs } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import IncubationTab from "./IncubationTab";
import Resourcestab from "./Resourcestab";
import InitiativeTab from "./InitiativeTab";
import PreincubationTab from "./PreincubationTab";

const Tncatalysthomepage = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`Stakeholders  >  TNCatalyst`} </span>
                <h1 className="banner-text-gradient">TNCATALYST</h1>
                <h6>Equipping Incubators with Tools for Success</h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Join Us</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage mt-5">
                <h4>What is TNCatalyst ?</h4>
                <p>
                  With over 120+ incubators across 30 districts of the state,
                  Tamil Nadu's incubator landscape is a testament to the state's
                  commitment to fostering innovation and entrepreneurship.
                  Catalyst provides the necessary resources to the incubators to
                  understand best practices and scale effectively. Whether you
                  are a new incubator or looking to enhance your impact,
                  Catalyst provides the knowledge and tools required for
                  sustainable growth and success.
                </p>
                <div className="left-content-actions">
                  <button className="ser-viewbtn">Join Us</button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/catalyst-pic.png "}
                  alt="TNCatalyst"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="Startups-count mt-5">
        <Container>
          <div className="details-div1">
            <div className="det-lines">
              <div className="startup-info">
                <div>
                  <img src={"/images/green-energy (1) 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>124+</h3>
                  <p>Incubation Centers</p>
                </div>
              </div>
              <div className="startup-info">
                <div>
                  <img src={"/images/Group 1707479617.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>2500+</h3>
                  <p>Startups Incubated</p>
                </div>
              </div>
              <div className="startup-info">
                <div>
                  <img src={"/images/startup 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>32.23L+Sq.ft</h3>
                  <p>Incubation Space</p>
                </div>
              </div>
              <div className="startup-info">
                <div>
                  <img src={"/images/diagram 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>30+</h3>
                  <p>Sectors Focused</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="mt-5">
        <TabContext value={value} className="tab-div mt-5">
          <div className="name-tab">
            <Box className="tabs-boxs">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Incubation"
                  value="1"
                  style={{
                    backgroundColor: value === "1" ? "#083fb2" : "",
                    color: value === "1" ? "white" : "#000",
                  }}
                  className="setFont"
                />
                <Tab
                  label="Resources"
                  value="2"
                  style={{
                    backgroundColor: value === "2" ? "#083fb2" : "",
                    color: value === "2" ? "white" : "#000",
                  }}
                  className="setFont"
                />

                <Tab
                  label="Iniatives"
                  value="3"
                  style={{
                    backgroundColor: value === "3" ? "#083fb2" : "",
                    color: value === "3" ? "white" : "#000",
                  }}
                  className="setFont"
                />
                <Tab
                  label="Pre-Incubation Centers"
                  value="4"
                  style={{
                    backgroundColor: value === "4" ? "#083fb2" : "",
                    color: value === "4" ? "white" : "#000",
                  }}
                  className="setFont"
                />
                <Tab
                  label="Corporate"
                  value="5"
                  style={{
                    backgroundColor: value === "5" ? "#083fb2" : "",
                    color: value === "5" ? "white" : "#000",
                  }}
                  className="setFont"
                />
                <Tab
                  label="CSR Spot"
                  value="6"
                  style={{
                    backgroundColor: value === "6" ? "#083fb2" : "",
                    color: value === "6" ? "white" : "#000",
                  }}
                  className="setFont"
                />
              </Tabs>
            </Box>
          </div>
          <TabPanel value="1" className="catalyst-tab"><IncubationTab/></TabPanel>
          <TabPanel value="2" className="catalyst-tab"><Resourcestab/></TabPanel>
          <TabPanel value="3" className="catalyst-tab"><InitiativeTab/></TabPanel>
          <TabPanel value="4" className="catalyst-tab"><PreincubationTab/></TabPanel>
          <TabPanel value="5" className="catalyst-tab"></TabPanel>
          <TabPanel value="6" className="catalyst-tab"></TabPanel>
        </TabContext>
        </div>
      </div>
      
    </div>
  );
};

export default Tncatalysthomepage;
