import React, { useContext, useState } from 'react';
import DataContext from '../../../context/DataContext';
import { useParams } from 'react-router-dom';
import { Button, Container, FormHelperText } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../api/notifySerivce';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import Viewimage from '../../../api/Viewimage';
import { FaInfoCircle } from "react-icons/fa";

const JoinusEoi = () => {
    const { id, smartcardid } = useParams();
    const { navigator, VisuallyHiddenInput, setPageLoading } = useContext(DataContext);
    const [formData, setFormData] = useState();
    const [isUploaded, setIsUploaded] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [viewImage, setViewImage] = useState("");
    const [viewImageName, setViewImageName] = useState("");

    const { handleSubmit, getValues, control, setValue, clearErrors, formState: { errors } } = useForm({ values: formData });
    let ids;
    if (id !== undefined) {
        ids = atob(id);
        debugger
    }
    let smartids
    if (smartcardid !== undefined) {
        smartids = atob(smartcardid)
        console.log(smartids,"idddddd");
        debugger
    }
    useDidMountEffect(() => {
        if (id !== undefined) {
            getApi()
        }
    }, [])

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = 'https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Smartcard_EOI_Template.pdf';
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    let logoselecetdFile = "";
    const handleFileUpload = (event, filekey, fileName, format) => {
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type;
                    var imagedatatype = imagetype.split("/");
                    var img_crt_type = imagedatatype[1];
                    if (
                        ((format === 'file') && (img_crt_type === "pdf")) ||
                        ((format === 'image') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
                    ) {
                        setValue(filekey, "");
                        setValue(fileName, "");
                        // setValue(fileUrl, '')
                        var fileValue = logoselecetdFile;
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(",");
                            var ImageValue = spl[1];
                            var img_name = fileValue.name;
                            // setUrl(logourl1)
                            setValue(filekey, ImageValue);
                            setValue(fileName, img_name);
                            // setValue(fileUrl, logourl1)
                            clearErrors(filekey);
                            clearErrors(fileName);
                            setIsUploaded(true);
                            // clearErrors(fileUrl);
                            var req = {
                                [filekey]: ImageValue,
                                [fileName]: img_name,
                                smartCardProductServiceId: Number(smartids)
                            }
                            // console.log(req);
                            Eoisave(req)
                            // 
                            // clearErrors('dpiitCertificateName')
                        };
                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        `Maximum file size allowed is 5 MB`
                    );
                }
            }
        }
    };
    const handleBackjoin = () => {
        navigator(`/smartcard/joinuspartner/${btoa(ids)}/list/${btoa(smartids)}`);
    };
    const [pdfUrl, setPdfUrl] = useState(null);
    const getApi = () => {
        setPageLoading(true);
        apiService(`smartcard/eoi/get?id=${smartids}`, "", "get")
            .then((result) => {
                setPageLoading(false);
                if (result && result.data) {
                    const data = result.data;
                    setFormData(data);
                    if (result.data.eoiDoc) {
                        setIsUploaded(true);
                        setPdfUrl(data.eoiDoc);
                    } else {
                        setIsUploaded(false);
                    }
                }
            })
            .catch((err) => {
                setPageLoading(false);
                console.error(err);
            });
    };
    const Eoisave = (req) => {
        setPageLoading(true);
        apiService('smartcard/eoi/save', req, 'post').then((res) => {
            setPageLoading(false);
            if (res && res.data) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', "Success", "EOI Form upload Successfully")
                    getApi()
                }
            }
        }).catch((err) => {
            console.log(err);

        });
    }
    const onSubmit = () => {
        setPageLoading(true)
        var req = {
            status: 1,
            smartcardId: Number(smartids)
        }
        apiService("smartcard/status/update", req, "post")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data && result.data.responseStatus === "Success") {
                    navigator(`/smartcard/joinuspartner/${btoa(ids)}/status/${btoa(smartids)}`);
                    localStorage.setItem('joinasstatus', 1)
                }
            })
            .catch((err) => { });

    };
    const [open, setOpen] = useState(false);
    const handleClickOpen = (value, name, label) => {
        setViewImage(value);
        setViewImageName(name);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='joinus-design'>
                        <div className='mt-4'>
                            <span className='bck-join c-pointer' onClick={handleBackjoin}>{`< Back`}</span>
                        </div>
                        <div className='title-joinus text-center pb-3'>
                            <h2 className='join-h3 mb-2'>Join as a Service Partner</h2>
                            <p>Join our network of trusted service partners and grow together.</p>
                            <div className='my-3'>
                                <img src="/images/join-us/second.png" alt="tab" />
                            </div>
                            <h6><b>Download, Fill & Upload Your EOI Form</b></h6>
                        </div>
                    </div>
                    <div className='pb-5'>
                        <div className='steeper-box'>
                            <Stepper activeStep={activeStep} orientation="vertical">

                                <Step>
                                    <StepLabel
                                        // icon={isDownloaded ? <CheckCircleIcon color="success" /> : <img src="/images/join-us/circle-1.png" alt="1" style={{width:'20px'}}/>}
                                        icon={<CheckCircleIcon color="success" />}
                                    >
                                        <span className='ste-1'>
                                            [<span
                                                style={{
                                                    color: '#083FFF',
                                                    fontWeight: '600',
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={handleDownload}
                                            >
                                                Download EOI Form
                                            </span>
                                            ]
                                            – Click to download the Expression of Interest (EOI) form.
                                        </span>
                                    </StepLabel>
                                </Step>

                                <Step>
                                    <StepLabel
                                        icon={isUploaded ? <CheckCircleIcon color="success" /> : <img src="/images/join-us/circle-2.png" alt="2" style={{ width: '20px' }} />}
                                    >
                                        <span className='ste-1'>
                                            Fill out the form & Upload your filled form here<br />

                                            <span className='ml-2'>
                                                <img src="/images/join-us/Arrow 8.png" alt="arrow" />
                                            </span>
                                            <div>
                                                <span className='d-flex align-items-center ml-3'>

                                                    <Controller
                                                        name="eoiDoc"
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: 'EOI Form is required' }}
                                                        render={({ field }) => (
                                                            <Button
                                                                {...field}
                                                                component="label"
                                                                sx={{ background: "#083FFF", textTransform: 'none', textDecoration: 'underline', width: '150px' }}
                                                                variant="contained"
                                                                fullWidth
                                                                onChange={(e) => handleFileUpload(e, 'eoi', 'eoiDoc', 'file')}

                                                            >
                                                                {isUploaded ? "Uploaded" : "Upload Here"}
                                                                <VisuallyHiddenInput type="file" />
                                                            </Button>

                                                        )}
                                                    />

                                                    <FormHelperText className="text-danger">
                                                        {errors.eoiDoc && errors.eoiDoc.message}
                                                    </FormHelperText>
                                                    {getValues("eoiDoc") && (<div className='ml-3 c-pointer' onClick={() =>
                                                        handleClickOpen(
                                                            getValues("eoi"),
                                                            getValues("eoiDoc"),
                                                        )
                                                    }>
                                                        <FaRegEye />
                                                    </div>)}

                                                </span>
                                                <span className=" mt-1 " style={{ fontSize: '12px' }}>
                                                    Maximum 5 mb allowed doc (Accepted Format: pdf)

                                                </span>
                                            </div>
                                        </span>
                                    </StepLabel>
                                </Step>

                            </Stepper>
                        </div>
                    </div>
                    <div className='text-center mt-2 pb-4'>
                        {/* className='add-sub-join-btn' */}
                        <Button variant="contained" type="submit" disabled={!isUploaded} sx={{ textTransform: 'none' }}>Submit For Review</Button>
                    </div>
                </form>
            </Container>
            <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
            />
        </div>
    );
};

export default JoinusEoi;