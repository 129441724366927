import React, { useContext, useState } from "react";
import { Container, Grid, TextField, Autocomplete, FormControl, FormHelperText, Pagination, } from "@mui/material";
import { Typewriter } from "react-simple-typewriter";
import { Controller, useForm } from "react-hook-form";
import { MdArrowDropDownCircle } from "react-icons/md";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { MdMailOutline } from "react-icons/md";
import { FaCircleArrowRight } from "react-icons/fa6";
import { RiCloseCircleFill } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import apiService from "../../api/apiService";
// import { useEffect } from "react";
// import Norecordsfound from "../common-pages/Norecordsfound";
import Navbar from "../navbar-page/Navbar";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../footer-page/Footer";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";

const Team = () => {
    const { setPageLoading, navigator, setUserProfileId, setUserProfileParsona, setShowViewbtn, showViewbtn } = useContext(DataContext);
    const [totalPage, setTotalPage] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState();
    const [sectorId, setSectorId] = useState();
    const [districtId, setDistrictId] = useState();
    const [hubDistrictId, setHubDistrictId] = useState(0);
    const [selectedOption, setSelectedOption] = useState("DPIIT Startup");
    const [page, setPage] = useState(1);
    const [inchargeSectorId, setInchargeSectorId] = useState()
    useDidMountEffect(() => {
        // userlistApi();
    }, [searchInputValue, sectorId, districtId, hubDistrictId, selectedOption, page,]);
    const [sectorList, setSectorList] = useState([]);
    const [sectorval, setSectorval] = useState({});
    const [inchargemail, setInchargemail] = useState("")

    const userlistApi = () => {
        setSectorList([]);
        setPageLoading(true);
        var data = {
            listSize: 12,
            pageNumber: page,
            searchString: searchInputValue,
            sectorId: sectorId,
            districtId: districtId,
            hubId: hubDistrictId,
            role: selectedOption,
        };
        apiService("ecosystem/list", data, "unauthpost")
            .then((result) => {
                setPageLoading(false);
                if (result) {
                    if (result.data) {
                        setInchargeSectorId(result.data.sectorId)
                        if (result.data.sector) {
                            setSectorval(result.data.sector);
                            if (result.data.sector.inchargEmail) {
                                var emailincharge = result.data.sector.inchargEmail
                                setInchargemail(cryptodecryptJS(emailincharge))
                            }
                        }
                        setTotalPage(result.data.totalPages);
                        if (result.data.userProfiles) {
                            setSectorList(result.data.userProfiles);

                        }
                    }
                }
            })
            .catch((err) => { });
    };
    const team1 = [
        {
            "img": "/images/Team_New/rajan_BL.png",
            "name": "Rajan B L",
            "position": "Vice President",
            "linkedin": "https://in.linkedin.com/in/rajanblu"
        },
        {
            "img": "/images/Team_New/sivakumar_apalanisamy.png",
            "name": "Sivakumar Palanisamy",
            "position": "Vice President",
            "linkedin": "https://www.linkedin.com/in/sivakumarsachin/"
        },
        {
            "img": "/images/Team_New/Dineshkumar_s.png",
            "name": "Dinesh Kumar S",
            "position": "Vice President",
            "linkedin": "https://www.linkedin.com/in/dinesh-sundaravelu-641b501a/"
        }
    ]

    const team = [

        {
            "img": "/images/Team_New/sai_ranganathan.png",
            "name": "Sai Ranganathan",
            "position": "Associate Vice President",
            "linkedin": "https://www.linkedin.com/in/sai-ranganathan-58785310"
        },
        {
            "img": "/images/Team_New/nikunj.png",
            "name": "Nikunj	Panchal",
            "position": "Associate Vice President",
            "linkedin": "https://www.linkedin.com/in/inikunj/"
        },
        {
            "img": "/images/Team_New/stalin.png",
            "name": "Stalin	Jacob J",
            "position": "Associate Vice President",
            "linkedin": "https://www.linkedin.com/in/stalinjacob/"
        },

        {
            "img": "/images/Team_New/ponni.png",
            "name": "Ponni Velmurugan",
            "position": "Associate Vice President",
            "linkedin": "https://www.linkedin.com/in/ponni-velmurugan-3b3a3275"
        },
        {
            "img": "/images/Team_New/Akila.png",
            "name": "Akila S",
            "position": "Associate Vice President",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/Hari Jayanth.png",
            "name": "Hari Jayanth",
            "position": "Associate Vice President",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/Siva Sankaran R.png",
            "name": "Siva Sankaran R",
            "position": "Associate Vice President",
            "linkedin": "https://www.linkedin.com/in/siva-sankaran-r-9573a4121/"
        },
        {
            "img": "/images/Team_New/Mageswar R.png",
            "name": "Mageswar R",
            "position": "Associate Vice President",
            "linkedin": "https://www.linkedin.com/in/mageswarradhakrishnan"
        },
        {
            "img": "/images/Team_New/prem.png",
            "name": "Prem",
            "position": "Associate Vice President",
            "linkedin": ""
        },

    ]

    const team3 = [
        {
            "img": "/images/Team_New/vijayalakshmi.png",
            "name": "Vijayalakshmi Ganesan",
            "position": "Project Lead",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/ramajeyam.png",
            "name": "Ramajeyam R M J",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/ramajeyam-rmj/"
        },
        {
            "img": "/images/Team_New/suganya_selvaraj.png ",
            "name": "Suganya Selvaraj",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/suganya-selvaraj-4a1411155/"
        },
        {
            "img": "/images/Team_New/senthamil.png",
            "name": "Senthamil Arasan A",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/senthamilarasan23"
        },
       
        {
            "img": "/images/Team_New/sakthivel_kaliappan.png ",
            "name": "Sakthivel Kaliappan",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/sakthivel-kaliappan-37a59ba7"
        },


        {
            "img": "/images/Team_New/rahulN.png",
            "name": "Rahul N",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/rahul-n-rl-97051b66/"
        },
        {
            "img": "/images/Team_New/venkadeswari.png",
            "name": "Venkadeswari N",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/venkadeswari-n-60a58ba3"
        },
        {
            "img": "/images/Team_New/vinoth_rajendran.png",
            "name": "Vinoth	Rajendran",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/vinothrajendranip/"
        },
        {
            "img": "/images/Team_New/ezhilarasan.png",
            "name": "Ezhilarasan S",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/ezhilarasan-s-2097691a/"
        },
        {
            "img": "/images/Team_New/devanathan.png",
            "name": "Devanathan	Veerappan",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/deva-nathan-696b8239/"
        },
        {
            "img": "/images/Team_New/prashanthini.png",
            "name": "Prashanthi B",
            "position": "Project Lead",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/subramanian N.png",
            "name": "Subramanian N",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/subramanian-n-205a6327b/"
        },
        {
            "img": "/images/Team_New/gayathri.png",
            "name": "Gayathri C",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/gayathri-chidambaram-1149b2a6"
        },
        {
            "img": "/images/Team_New/gigin_durai.png",
            "name": "Gigin Durai C",
            "position": "Project Lead",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/umasankaran.png",
            "name": "Umasankaran Panner Selvam",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/umasankaran2011/"
        },
        {
            "img": "/images/Team_New/srAneesh.png",
            "name": "S R Aneesh",
            "position": "Project Lead ",
            "linkedin": "https://www.linkedin.com/in/aneesh-s-r-1765b81a3/"
        },
        {
            "img": "/images/Team_New/jayagobi.png",
            "name": "Jayagobi S",
            "position": "Project Lead ",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/gurushankar.png ",
            "name": "Gurushankar S",
            "position": "Project Lead",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/gopalakrishanan.png ",
            "name": "Gopalakrishnan K",
            "position": "Project Lead",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/bharathakumar.png",
            "name": "Bharathakumar S",
            "position": "Project Lead",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/karupannan.png",
            "name": "Karuppanan	P",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/prabu-karuppanan-8103622b"
        },
        {
            "img": "/images/Team_New/poongodi.png",
            "name": "Poongodi M",
            "position": "Project Lead",
            "linkedin": "https://www.linkedin.com/in/poongodi-mathiyalagan-184769191/"
        }
    ]

    const team4 = [
        {
            "img": "/images/Team_New/ragesh uk.png",
            "name": "Ragesh	U K",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/ragesh-uk-468382195/"
            
        },
        {
            "img": "/images/Team_New/Gowtham B.png",
            "name": "Gowtham B",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/gowtham-b-51a3271ba/"
        },
        {
            "img": "/images/Team_New/rajsekar sivasamy.png",
            "name": "Rajsekar Sivasamy",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/rajsekar-sivasamy-0bb815185/"
        },
        {
            "img": "/images/Team_New/saranya.png",
            "name": "Saranya Suthandra Pandian ",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/saranya-suthandra-pandian-476438253"
        },
        // {
        //     "img": "/images/Team_New/selvam sekar.png",
        //     "name": "Selvam	Sekar",
        //     "position": "Project Associate",
        //     "linkedin": ""
        // },
       
      
        {
            "img": "/images/Team_New/ajay annamalai.png",
            "name": "Ajay Annamalai",
            "position": "Project Associate",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/preethi.png",
            "name": "Preethi Sivan",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/preethi-sivan-452a9b15b/"
        },
        {
            "img": "/images/Team_New/udhayakumar.png",
            "name": "Udhayakumar Selvarasu",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/udhayakumar-selvarasu-b34258253"
        },
        {
            "img": "/images/Team_New/kaavya Balasubramaniam.png",
            "name": "Kaavya	Balasubramanian",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/kaavyabalasubramanian/"
        },
        {
            "img": "/images/Team_New/Nagathilagam.png",
            "name": "Nagathilagam M",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/naga-thilagam-496733277"
        },
        {
            "img": "/images/Team_New/DhivyaBharathi K.png",
            "name": "Dhivyabharathi	K",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/dhivya-krishnan-761623260"
        },
        {
            "img": "/images/Team_New/Maheshkumar.png",
            "name": "Maheshkumar S",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/mahesh-kumar-07379816b/"
        },
        // {
        //     "img": "/images/Team_New/Abarna renkarajan.png",
        //     "name": "Abarna	Renkarajan",
        //     "position": "Project Associate",
        //     "linkedin": ""
        // },
        {
            "img": "/images/Team_New/Anirudh.png",
            "name": "Anirudh Gurumurthi",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/anirudhg1999/"
        },
        {
            "img": "/images/Team_New/vibith.png",
            "name": "Vibith	S",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/vibith96 "
        },
        {
            "img": "/images/Team_New/gobinath.png",
            "name": "Gobinath Thangamuthu",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/gobinath-t"
        },
       
        {
            "img": "/images/Team_New/maran.png",
            "name": "Maran D",
            "position": "Project Associate",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/suman D.png",
            "name": "Suman D",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/suman-d-976a6148/"
        },
        {
            "img": "/images/Team_New/naveenkumars.png",
            "name": "Naveen Kumar S",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/naveen-kumar-somasundaram-62600b261/"
        },
        {
            "img": "/images/Team_New/renuka d.png",
            "name": "Renuka D",
            "position": "Project Associate",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/Ashwanth r.png",
            "name": "Ashwanth R",
            "position": "Project Associate",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/shajuNs.png",
            "name": "Shaju Ns",
            "position": "Project Associate",
            "linkedin": ""
        },
       
        {
            "img": "/images/Team_New/deepakraju.png",
            "name": "Deepakraju Sivanesan",
            "position": "Project Associate",
            "linkedin": ""
        },
        {
            "img": "/images/Team_New/Mohan r.png",
            "name": "Mohan R",
            "position": "Project Associate",
            "linkedin": "https://www.linkedin.com/in/mohan-r-4102my2831/"
        },
        {
            "img": "/images/Team_New/manoj kumar _ sm.png",
            "name": "Manoj Kumar SM",
            "position": "Project Associate",
            "linkedin": ""
        }
    ]

    const handleclickLink = (link) => {
        if(link){
        window.open(link, '_blank', 'noopener noreferrer')
    }
    }

    return (
        <div className="back-color1">
            <Navbar />
            <div className="back-layer"></div>
            <div className="back-layer1"></div>
            {/* <div className="top-layer"></div> */}
            <div className="pb-5">
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12}>
                        <Container>
                            <div className="main-title">
                                <p className="team-heading mb-0">Meet our Team</p>
                                <div className="vectorlineimg">
                                    <img className="vector-line" src={"./images/Vector (1).png"} alt="vector" />
                                </div>

                                <p className="my-4" style={{ color: '#858585' }}>Meet our team of professional to serve you</p>
                            </div>
                        </Container>
                    </Grid>
                </Grid>

                <Container maxWidth="md">
                    <Grid
                        container
                        className="responsive-grid"
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            {/* <div className="container-team2">
                                <div className="background-box-top"></div>
                                <div className="background-team-img">
                                    <div className="team-circle">
                                        <img src="/images/team/min.png" className="team-people img-fluid" alt="" />
                                    </div>
                                </div>
                            </div> */}
                            <div className="container-team1">
                                <div className="background-box1"></div>
                                <div className="background-team5-img">
                                    <div className="text-center my-3">
                                        <img src={'/images/Team_New/Anbarasan.png'} className="team-people img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="minis-text">
                                <h5 style={{ color: '#083FB2', fontWeight: 'bold' }}>Minister</h5>
                                <h5 style={{ fontWeight: 'bold' }}>Thiru. T.M. Anbarasan</h5>
                                <h6 style={{ color: '#8c8b8c' }}>Hon'ble Minister for Micro, Small, and </h6>
                                <h6 style={{ color: '#8c8b8c' }}>Medium Enterprises</h6>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <Container maxWidth="md">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"

                    >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="sec-team" >
                                <div style={{ textAlign: 'center' }}>
                                    <h5 style={{ color: '#083FB2', fontWeight: 'bold' }}>Secretary to Government</h5>
                                    <h5 style={{ fontWeight: 'bold' }}>Tmt. Archana Patnaik, IAS</h5>
                                    <h6 style={{ color: '#8c8b8c' }}>Secretary to Government</h6>
                                    <h6 style={{ color: '#8c8b8c' }}>MSME Department</h6>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="cc-img" >
                                {/* <div className="container-team1">
                                    <div className="background-box-top"></div>
                                    <div className="background-team-img">
                                        <div className="team-circle">
                                            <img src="/images/team/ias.png" className="team-people img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="container-team1">
                                    <div className="background-box-n"></div>
                                    <div className="background-team5-img">
                                        <div className="text-center my-3">
                                            <img src={'/images/Team_New/Archana.png'} className="team-people img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>


                <div className="center-card">
                    {/* <div className="container-team1">
                        <div className="background-box-top"></div>
                        <div className="background-team-img">
                            <div className="team-circle">
                                <img src="/images/team/sivaraj.png" className="team-people img-fluid" alt="" />
                            </div>
                        </div>
                    </div> */}
                    <div className="container-team1">
                        <div className="background-box-n"></div>
                        <div className="background-team5-img">
                            <div className="text-center my-3">
                                <img src={'/images/Team_New/sivarajah.png'} className="team-people img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center my-5">
                    <h5 style={{ fontWeight: 'bold' }}>Thiru. Sivarajah Ramanathan <span className="ml-2"> <Link to="https://www.linkedin.com/in/sivarajahr/" target="_blank"><img src="/images/team/linkedin.png" className="img-fluid c-pointer" alt="" style={{ width: '35px' }} /></Link></span></h5>
                    <h6 style={{ color: '#8c8b8c' }}>Mission Director & CEO</h6>
                    <h6 style={{ color: '#8c8b8c' }}>Tamil Nadu Startup & Innovation Mission (StartupTN)</h6>

                </div>
                <Container maxWidth="lg" className="my-5">
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{ borderBottomStyle: 'dashed', borderColor: 'lightgray' }}
                    >
                        {
                            team1.length > 0 ? (
                                team1 && team1.map((row, index) => (
                                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3} key={index} className="my-5" >
                                        <div className="container-team">
                                            <div className="background-box"></div>
                                            <div className="background-team2-img">
                                                <div className="text-center my-3">
                                                    <img src={row.img ? row.img : '/images/team/dummy.jpg'} className="team-people2 img-fluid" alt="" />
                                                </div>
                                                <p style={{ color: '#020671', fontSize: '14px', textAlign: 'center', fontWeight: '600' }}>{row.name}</p>
                                                <p style={{ color: '#00070B', fontSize: '10px', textAlign: 'center', }}>{row.position}</p>
                                                {row.linkedin ? ( <div className="text-center c-pointer " onClick={() => handleclickLink(row.linkedin)} > <img src={'/images/team/linkedin.png'} className="linkedin-size img-fluid" alt="" /></div>):( <div className="dis-nonw-cont" onClick={() => handleclickLink(row.linkedin)} style={{visibility:'hidden'}}> <img src={'/images/team/linkedin.png'} className="linkedin-size img-fluid" alt="" /></div>)}
                                               
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <></>
                            )
                        }


                    </Grid>
                </Container>

                <center><h3 style={{ color: '#083dab', fontWeight: 'bold' }}>Team</h3></center>
                <Container maxWidth="lg" className="my-5">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{ borderBottomStyle: 'dashed', borderColor: 'lightgray' }}
                    >
                        {
                            team.length > 0 ? (
                                team && team.map((row, index) => (
                                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3} key={index} className="my-5" >
                                        <div className="container-team">
                                            <div className="background-box"></div>
                                            <div className="background-team2-img">
                                                <div className="text-center my-3">
                                                    <img src={row.img ? row.img : '/images/team/dummy.jpg'} className="team-people2 img-fluid" alt="" />
                                                </div>
                                                <p style={{ color: '#020671', fontSize: '14px', textAlign: 'center', fontWeight: '600' }}>{row.name}</p>
                                                <p style={{ color: '#00070B', fontSize: '10px', textAlign: 'center', }}>{row.position}</p>
                                                {row.linkedin ? ( <div className="text-center c-pointer " onClick={() => handleclickLink(row.linkedin)} > <img src={'/images/team/linkedin.png'} className="linkedin-size img-fluid" alt="" /></div>):( <div className="dis-nonw-cont" onClick={() => handleclickLink(row.linkedin)} style={{visibility:'hidden'}}> <img src={'/images/team/linkedin.png'} className="linkedin-size img-fluid" alt="" /></div>)}
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <></>
                            )
                        }
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="my-5">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{ borderBottomStyle: 'dashed', borderColor: 'lightgray' }}
                    >
                        {
                            team3.length > 0 ? (
                                team3 && team3.map((row, index) => (
                                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3} key={index} className="my-5" >
                                        <div className="container-team">
                                            <div className="background-box"></div>
                                            <div className="background-team2-img">
                                                <div className="text-center my-3">
                                                    <img src={row.img ? row.img : '/images/team/dummy.jpg'} className="team-people2 img-fluid" alt="" />
                                                </div>
                                                <p style={{ color: '#020671', fontSize: '14px', textAlign: 'center', fontWeight: '600' }}>{row.name}</p>
                                                <p style={{ color: '#00070B', fontSize: '10px', textAlign: 'center', }}>{row.position}</p>
                                                {row.linkedin ? ( <div className="text-center c-pointer " onClick={() => handleclickLink(row.linkedin)} > <img src={'/images/team/linkedin.png'} className="linkedin-size img-fluid" alt="" /></div>):( <div className="dis-nonw-cont" onClick={() => handleclickLink(row.linkedin)} style={{visibility:'hidden'}}> <img src={'/images/team/linkedin.png'} className="linkedin-size img-fluid" alt="" /></div>)}
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <></>
                            )
                        }
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="my-5">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                        // sx={{ borderBottomStyle: 'dashed', borderColor: 'lightgray' }}
                    >
                        {
                            team4.length > 0 ? (
                                team4 && team4.map((row, index) => (
                                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3} key={index} className="my-5" >
                                        <div className="container-team">
                                            <div className="background-box"></div>
                                            <div className="background-team2-img">
                                                <div className="text-center my-3">
                                                    <img src={row.img ? row.img : '/images/team/dummy.jpg'} className="team-people2 img-fluid" alt="" />
                                                </div>
                                                <p style={{ color: '#020671', fontSize: '14px', textAlign: 'center', fontWeight: '600' }}>{row.name}</p>
                                                <p style={{ color: '#00070B', fontSize: '10px', textAlign: 'center', }}>{row.position}</p>
                                                {row.linkedin ? ( <div className="text-center c-pointer " onClick={() => handleclickLink(row.linkedin)} > <img src={'/images/team/linkedin.png'} className="linkedin-size img-fluid" alt="" /></div>):( <div className="dis-nonw-cont" onClick={() => handleclickLink(row.linkedin)} style={{visibility:'hidden'}}> <img src={'/images/team/linkedin.png'} className="linkedin-size img-fluid" alt="" /></div>)}
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <></>
                            )
                        }
                    </Grid>
                </Container>
            </div>
            <div className="pt-5">
            <Footer />
            </div>
        </div>
    );
};

export default Team;
