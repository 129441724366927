import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import LanguageIcon from "@mui/icons-material/Language";
import DataContext from "../../../context/DataContext";
const Language = () => {
  const { changeLanguage } = useContext(DataContext);
  const { register, setValue } = useForm({});

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (lang === null) {
      setValue("lange", "ta");
    } else {
      setValue("lange", lang);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form className="d-flex align-items-center">
      <img src="./images/lang.png" class="lang-i" alt="lang-icon" />
      {/* <select
        className="lag_select mx-1 mt-1 c-pointer"
        style={{
          border: "none",
          color: "#fff",
          borderRadius: '5px',
          background: '#00023A',
          padding: '4px 6px',
          fontVariant: " all-petite-caps",
          fontSize: "18px"
        }}
        {...register("lange", {
          onChange: (e) => {
            changeLanguage(e);
          },
        })}
      >
        <option value="en">English</option>
        <option value="ta">தமிழ்</option>
      </select> */}
      <div>

        <select
          aria-label="Select Language"
          className="lag_select mx-1 mt-1 c-pointer"
          style={{
            border: "none",
            color: "#fff",
            borderRadius: '5px',
            background: '#00023A',
            padding: '4px 6px',
            fontVariant: "all-petite-caps",
            fontSize: "18px"
          }}
          {...register("lange", {
            onChange: (e) => {
              changeLanguage(e);
            },
          })}
        >
          <option value="en">English</option>
          <option value="ta">தமிழ்</option>
        </select>
      </div>

    </form>
  );
};

export default Language;
