import React from 'react'
import InitiativeLayout from './InitiativeLayout'
import FundingHomePage from './funding-pages/FundingHomePage'
import LiteracyHomePage from './literacy-pages/LiteracyHomePage'
import FundingTanseed from './funding-pages/FundingTanseed'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import SupportAcceleratorPage from './support-pages/SupportAcceleratorPage'
import S2gPage from './market-access/S2gPage'
import LaunchPad from './market-access/LaunchPad'
import TamilAngelsHomePage from './funding-pages/TamilAngelsHomePage'
import EmergingSectorHomePage from './funding-pages/EmergingSectorHomePage'
import EmergingSectorSeedHomePage from './equitable-growth-page/EmergingSectorSeedHomePage'
import SupportSmartCardService from '../smart-card-module/smart-card-page/SupportSmartCardService'
import AboutCompany from '../smart-card-module/smart-card-page/AboutCompany'
import Hackathon from './initiative-page/Hackathon'
import Bilingualcallpage from './support-pages/Bilingualcallpage'
import SmartShgpage from './equitable-growth-page/SmartShgpage'
import ConventionalInstitutionpage from './investments-page/ConventionalInstitutionpage'
import StartupThamizha from './investments-page/StartupThamizha'
import VentureLabpage from './support-pages/VentureLabpage'
import AadukalamPage from './literacy-pages/AadukalamPage'
import Breakthebarrierspage from './literacy-pages/Breakthebarrierspage'
import BrandLabs from './literacy-pages/BrandLabs'
import BeyondTn from './market-access/BeyondTn'
import InnovateTnfablab from './literacy-pages/InnovateTnfablab'
import ScaleupIncubationsupport from './ecosystem-stakeholders-page/ScaleupIncubationsupport'
import SectoralForum from './ecosystem-stakeholders-page/SectoralForum'
import SmartCardPage from '../smart-card-module/smart-card-page/SmartCardPage'
import Tanfund from './funding-pages/Tanfund'
// import ScStFund from './funding-pages/ScStFund'
import MentorHome from './support-pages/mentor-pages/MentorHome'
import MentorAbout from './support-pages/mentor-pages/MentorAbout'
import MentorTeam from './support-pages/mentor-pages/MentorTeam'
import MentorSessions from './support-pages/mentor-pages/MentorSessions'
import Tncatalysthomepage from './ecosystem-stakeholders-page/TNCatalyst-page/Tncatalysthomepage'
import OpenInnovationHome from './innovation-page/open-innovation/OpenInnovationHome'
import OpenInnovationLayout from './innovation-page/open-innovation/OpenInnovationLayout'
import OpenInnovationCorporate from './innovation-page/open-innovation/OpenInnovationCorporate'
import OpenInnovationGovernment from './innovation-page/open-innovation/OpenInnovationGovernment'
import GCC from './market-access/GCC'

const InitiativeRoutes = () => {
    return (
        <Routes>
            <Route path='/initiative' element={<InitiativeLayout />}>
                <Route path="/initiative" element={<Navigate replace to="/initiative/funding" />} />
                <Route path="/initiative/funding" element={<FundingHomePage />} />
                <Route path="/initiative/funding/tanseed" element={<FundingTanseed name={'Funding'} />} />
                <Route path="/initiative/funding/tamilangels" element={<TamilAngelsHomePage name={'Funding'} />} />
                <Route path="/initiative/funding/emergingsector" element={<EmergingSectorHomePage />} />
                <Route path="/initiative/funding/conventionalInstitutions" element={<ConventionalInstitutionpage />} />
                <Route path="/initiative/funding/startupthamizha" element={<StartupThamizha />} />
                <Route path="/initiative/funding/tanfund" element={<Tanfund />} />
                {/* <Route path="/initiative/funding/scststarupfund" element={<ScStFund name={'Funding'}/>} /> */}
                <Route path="/initiative/funding/hackathon" element={<Hackathon name={'Funding'} />} />
                <Route path='/initiative/funding/openinnovation' element={<OpenInnovationLayout name={'Funding'} />} />
                <Route path="/initiative/funding/s2g" element={<S2gPage name={'Funding'} />} />
                <Route path="/initiative/funding/startuptnbrandlabs" element={<BrandLabs name={'Funding'} />} />
                <Route path="/initiative/funding/mentortn/home" element={< MentorHome name={'Funding'} url={'funding'}/>} />
                <Route path="/initiative/funding/mentortn/about" element={< MentorAbout name={'Funding'} url={'funding'}/>} />
                <Route path="/initiative/funding/mentortn/team" element={< MentorTeam name={'MentorTN'} url={'funding'}/>} />
                <Route path="/initiative/funding/mentortn/sessions" element={< MentorSessions name={'MentorTN'} url={'funding'}/>} />
                <Route path="/initiative/funding/sectoralforum" element={<SectoralForum name={'Funding'}/>} />
                <Route path="/initiative/funding/bilingualcall" element={<Bilingualcallpage name={'Funding'}/>} />
                <Route path="/initiative/funding/venturelab" element={<VentureLabpage name={'Funding'} />} />
                <Route path="/initiative/funding/smartcard" element={<SmartCardPage name={'Funding'} />} />
                <Route path="/initiative/funding/scaleupincubationsupport" element={<ScaleupIncubationsupport name={'Funding'}/>} />
                <Route path="/initiative/funding/innovatetnfablabs" element={<InnovateTnfablab name={'Funding'}/>} />
                <Route path="/initiative/funding/emergingsector" element={<EmergingSectorSeedHomePage name={'Funding'}/>} />
                {/* <Route path="/initiative/funding/scststarupfund" element={<ScStFund name={'Funding'}/>} /> */}
                <Route path="/initiative/funding/beyondtn" element={<BeyondTn name={'Funding'}/>} />
                <Route path="/initiative/funding/tncatalyst" element={<Tncatalysthomepage name={'Funding'}/>} />
                <Route path="/initiative/funding/sectoralforum" element={<SectoralForum name={'Funding'}/>} />
                <Route path="/initiative/funding/accelerator" element={<SupportAcceleratorPage  name={'Funding'}/>} />
                <Route path="/initiative/funding/launchpad" element={<LaunchPad name={'Funding'}/>} />
                
                
                <Route path="/initiative/literacy" element={<LiteracyHomePage />} />
                <Route path="/initiative/literacy/aadukalam" element={<AadukalamPage />} />
                <Route path="/initiative/literacy/breakthebarriers" element={<Breakthebarrierspage />} />
                <Route path="/initiative/literacy/hackathon" element={<Hackathon name={'Literacy'} />} />
                <Route path="/initiative/literacy/startuptnbrandlabs" element={<BrandLabs name={'Literacy'} />} />
                <Route path="/initiative/literacy/innovatetnfablabs" element={<InnovateTnfablab name={'Literacy'}/>} />
                <Route path="/initiative/literacy/mentortn/home" element={< MentorHome name={'Literacy'} url={'literacy'}/>} />
                <Route path="/initiative/literacy/mentortn/about" element={< MentorAbout name={'MentorTN'} url={'literacy'}/>} />
                <Route path="/initiative/literacy/mentortn/team" element={< MentorTeam name={'MentorTN'} url={'literacy'}/>} />
                <Route path="/initiative/literacy/mentortn/sessions" element={< MentorSessions name={'MentorTN'} url={'literacy'}/>} />
                

                <Route path="/initiative/support/accelerator" element={<SupportAcceleratorPage  name={'support'}/>} />
                <Route path="/initiative/support/smartcard" element={<SmartCardPage name={'support'} />} />
                <Route path="/initiative/support/smartcard/service" element={<SupportSmartCardService name={'support'} />} />
                <Route path="/initiative/support/Bilingualcall" element={<Bilingualcallpage />} />
                <Route path="/initiative/support/sectoralforum" element={<SectoralForum name={'Support'}/>} />
                <Route path="/initiative/support/venturelab" element={<VentureLabpage name={'Support'} />} />
                <Route path="/initiative/support/mentortn/home" element={< MentorHome name={'Support'} url={'support'}/>} />
                <Route path="/initiative/support/mentortn/about" element={< MentorAbout name={'MentorTN'} url={'support'}/>} />
                <Route path="/initiative/support/mentortn/team" element={< MentorTeam name={'MentorTN'} url={'support'}/>} />
                <Route path="/initiative/support/mentortn/sessions" element={< MentorSessions name={'MentorTN'} url={'support'}/>} />



                <Route path="/initiative/marketaccess/s2g" element={<S2gPage name={'Marketaccess'} />} />
                <Route path="/initiative/marketaccess/launchpad" element={<LaunchPad name={'Marketaccess'}/>} />
                <Route path="/initiative/marketaccess/beyondtn" element={<BeyondTn name={'Marketaccess'}/>} />
                <Route path="/initiative/marketaccess/beyondtn/gcc" element={<GCC />} />
                <Route path="/initiative/marketaccess/startuptnbrandlabs" element={<BrandLabs name={'Marketaccess'} />} />
                <Route path="/initiative/marketaccess/accelerator" element={<SupportAcceleratorPage name={'Marketaccess'}/>} />

                <Route path="/initiative/equitable/emergingsector" element={<EmergingSectorSeedHomePage name={'Equitable Growth'}/>} />
                <Route path="/initiative/equitable/smartshg" element={<SmartShgpage />} />
                <Route path="/initiative/equitable/tanseed" element={<FundingTanseed name={'Equitable Growth'} />} />
                <Route path="/initiative/equitable/venturelab" element={<VentureLabpage name={'Equitable Growth'} />} />
                {/* <Route path="/initiative/equitable/scststarupfund" element={<ScStFund name={'Equitable Growth'}/>} /> */}

                <Route path="/initiative/ecosystemstakeholders/scaleupincubationsupport" element={<ScaleupIncubationsupport name={'Ecosystem Stakeholders'}/>} />
                <Route path="/initiative/ecosystemstakeholders/s2g" element={<S2gPage name={'Ecosystem Stakeholders'} />} />
                <Route path="/initiative/ecosystemstakeholders/tamilangels" element={<TamilAngelsHomePage name={'Ecosystem Stakeholders'} />} />
                <Route path="/initiative/ecosystemstakeholders/tanfund" element={<Tanfund name={'Ecosystem Stakeholders'}/>} />
                <Route path="/initiative/ecosystemstakeholders/sectoralforum" element={<SectoralForum name={'Ecosystem Stakeholders'}/>} />
                <Route path="/initiative/ecosystemstakeholders/tncatalyst" element={<Tncatalysthomepage />} />
                <Route path="/initiative/ecosystemstakeholders/mentortn/home" element={< MentorHome name={'Ecosystem Stakeholders'} url={'ecosystemstakeholders'}/>} />
                <Route path="/initiative/ecosystemstakeholders/mentortn/about" element={< MentorAbout name={'Ecosystem Stakeholders'} url={'ecosystemstakeholders'}/>} />
                <Route path="/initiative/ecosystemstakeholders/mentortn/team" element={< MentorTeam name={'MentorTN'} url={'ecosystemstakeholders'}/>} />
                <Route path="/initiative/ecosystemstakeholders/mentortn/sessions" element={< MentorSessions name={'MentorTN'} url={'ecosystemstakeholders'}/>} />

                <Route path="/initiative/innovation/innovatetnfablabs" element={<InnovateTnfablab name={'Innovation'}/>} />
                <Route path="/initiative/innovation/hackathon" element={<Hackathon name={'Innovation'} />} />
                <Route path="/initiative/innovation/openinnovation" element={<OpenInnovationHome name={'Innovation'} />} />
                <Route path="/initiative/innovation/startuptnbrandlabs" element={<BrandLabs name={'Innovation'} />} />
                <Route path="/initiative/innovation/s2g" element={<S2gPage name={'Innovation'} />} />


                <Route path='/initiative/innovation/challenge' element={<OpenInnovationLayout name={'Innovation'} />}>
                    <Route path="/initiative/innovation/challenge" element={<Navigate replace to="/initiative/innovation/challenge/corporate" />} />
                    <Route path="/initiative/innovation/challenge/corporate" element={<OpenInnovationCorporate  name={'Innovation'} /> } />
                    <Route path="/initiative/innovation/challenge/gorvernment" element={<OpenInnovationGovernment  name={'Innovation'} /> } />
                </Route>

                
                <Route path="/initiative/infrastructure/innovatetnfablabs" element={<InnovateTnfablab name={'Infrastructure'}/>} />
                <Route path="/initiative/infrastructure/emergingsector" element={<EmergingSectorSeedHomePage name={'Infrastructure'}/>} />
                <Route path="/initiative/aboutCompany/:id" element={<AboutCompany />} />

            </Route>
        </Routes>
    )
}

export default InitiativeRoutes